.header {
  box-shadow: 0rem 0.1875rem 0.5rem rgba(72, 178, 255, 0.16);
  z-index: 2;
}
.logo {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  cursor: pointer;
  text-decoration: none;
}
.groupedRoutes {
  border-radius: 1.25rem;
  cursor: pointer;
}
.navLink {
  text-decoration: none;
}
.menuitem {
  height: 2.6rem;
  padding: 10px;
  cursor: pointer;
}
.menuitem:hover{
  background-color: #e9ecef;
  height: 2.6rem;
}

.active {
  width: 64%;
  height: 0.1875rem;
  border-radius: 0.09375rem;
  margin: 0 auto;
}

.activeKebabLink {
  font-weight: 700;
}

.navLink:hover {
  color: black;
}

.break {
  flex-basis: '100%';
}

.findDiv {
  display: flex;
  width: 100%;
}

.mainDiv {
  flex-flow: wrap;
}

.box::before,
.box::after { 
  content: '';
  position: absolute;
  background: red;
  border-radius: 50%;
  padding: 6px;
  animation-name: widgetAnimation;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  right: 1%;
  opacity: 0;
  z-index: -1;
}

.box::after {
  animation-delay: 1s;
}

@keyframes widgetAnimation {
  0% {
    transform: scale(0.7);
    opacity: 0.6;
  }
  40% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}