/* @font-face {
  font-family: "Circular Std" !important;
  src: url("/public/assets/font/circular-std/CircularStd-Black.ttf");
}

@font-face {
  font-family: "Circular Std";
  src: url("/public/assets/font/circular-std/CircularStd-Bold.ttf");
}

@font-face {
  font-family: "Circular Std";
  src: url("/public/assets/font/circular-std/CircularStd-Medium.ttf");
}

*/

:root {
  --body-font: 'Circular Std', sans-serif;
  --primary-color-rgb: 0, 159, 158;
  --secondary-color: #ffffff;
  --secondary-color-rgb: 42, 42, 42;
  --tertiary-color-rgb: 255, 204, 0;
  --body-color: #fafdff;
  --body-color-rgb: 0, 0, 0;
}

/* .header-css * {
  margin: 0;
  padding: 0;
} */

.header-css html {
  overflow-x: hidden;
}

.header-css body {
  font-family: var(--body-font);
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  overflow-x: hidden;
  position: relative;
  line-height: 21px;
  background-color: var(--body-color);
}

.section {
  position: relative;
  padding: 100px 0;
}

.section.section-padding {
  padding: 100px 0 70px;
}

.header-css img {
  max-width: 100%;
}

.header-css ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* Typography */

.header-css h1,
.header-css h2,
.header-css h3,
.header-css h4,
.header-css h5,
.header-css h6 {
  margin-bottom: 20px;
}

.header-css h1 {
  font-style: normal;
  font-weight: 200;
  font-size: 78px;
  line-height: 91px;
  /* text-transform: capitalize; */
}

.header-css h2 {
  font-size: 40px;
  line-height: 55px;
}

.header-css h3 {
  font-size: 36px;
  font-weight: 400;
  line-height: 49px;
}

.header-css h4 {
  font-size: 24px;
  font-weight: 500;
}

.header-css p {
  margin-bottom: 20px;
  font-family: var(--body-font);
  font-weight: 300;
}

.header-css a {
  color: var(--primary-color);
  transition: 0.3s;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.header-css a:hover,
.header-css a:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
  color: var(--secondary-color);
}

.section {
  padding: 50px 0;
}

.section-heading.centred {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.section-heading {
  margin-bottom: 30px;
}

.section-heading .section_head,
.section_head {
  color: #000;
  /* text-align: center; */
  font-size: 41px;
  font-family: var(--body-font);
  font-weight: 500;
  line-height: 45px;
  max-width: 74%;
  /* text-transform: capitalize */
}

.section-heading .section_head span,
.section_head span {
  background: -webkit-linear-gradient(
    rgba(73, 26, 235, 1),
    rgba(253, 91, 14, 1)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ======btns===== */

.header-css button {
  background-color: transparent;
}
.white-btn {
  --c: #000;
  color: var(--c);
  border-radius: 7px;
  width: fit-content;
  font-size: 16px;
  font-family: var(--body-font);
  font-weight: 500;
  line-height: 150%;
  color: #fff !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  padding: 7px 23px;
  border: 2px solid #1d8ff2;
  margin-right: 0;
  background-color: #1d8ff2;
  text-decoration: none;
}

.white-btn span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.white-btn:hover {
  color: var(--secondary-color);
  border: 2px solid #000;
}

.white-btn:hover span {
  transform: translateY(0) scale(2);
}

.white-btn span:nth-child(1) {
  --n: 1;
}

.white-btn span:nth-child(2) {
  --n: 2;
}

.white-btn span:nth-child(3) {
  --n: 3;
}

.white-btn span:nth-child(4) {
  --n: 4;
}

/* transparent-btn */

.transparent-btn {
  --c: #000;
  color: var(--c);
  border-radius: 7px;
  width: fit-content;
  font-size: 16px;
  font-family: var(--body-font);
  font-weight: 500;
  line-height: 150%;
  color: #000;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  padding: 7px 23px;
  border: 2px solid #dfdfdf;
  margin-right: 0;
  text-decoration: none;
}

.transparent-btn span {
  position: absolute;
  width: 25%;
  height: 100%;
  color: #fff;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.transparent-btn:hover {
  color: var(--secondary-color);
  border: 2px solid transparent;
}

.transparent-btn:hover span {
  transform: translateY(0) scale(2);
}

.transparent-btn span:nth-child(1) {
  --n: 1;
}

.transparent-btn span:nth-child(2) {
  --n: 2;
}

.transparent-btn span:nth-child(3) {
  --n: 3;
}

.transparent-btn span:nth-child(4) {
  --n: 4;
}

.blue-btn {
  --c: #fff;
  color: var(--c);
  border-radius: 7px;
  width: fit-content;
  font-size: 16px;
  font-family: var(--body-font);
  font-weight: 500;
  line-height: 150%;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  padding: 7px 23px;
  border: 2px solid #dfdfdf;
  margin-right: 0;
  text-decoration: none;
}
.blue-btn span {
  position: absolute;
  width: 25%;
  height: 100%;
  color: #000;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.blue-btn:hover {
  color: #000;
}

.blue-btn:hover span {
  transform: translateY(0) scale(2);
}

.blue-btn span:nth-child(1) {
  --n: 1;
}

.blue-btn span:nth-child(2) {
  --n: 2;
}

.blue-btn span:nth-child(3) {
  --n: 3;
}

.blue-btn span:nth-child(4) {
  --n: 4;
}

/* =======slider-dots===== */

ul.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.slick-dots li {
  margin: 0 4px;
}

ul.slick-dots li button {
  border: 0;
  background: #cccccc;
  color: transparent;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

ul.slick-dots li button::before {
  color: transparent;
}

ul.slick-dots li.slick-active button {
  background: #797979;
}

ul.slick-dots li.slick-active button::before {
  color: transparent;
}

.slick-dots {
  bottom: -39px;
}

.slide-arrow {
  border: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.slide-arrow.prev-arrow {
  position: absolute;
  top: 46%;
  left: -1%;
  z-index: 1;
  background: #fff;
  cursor: pointer;
  box-shadow: 1px 3px 4px #b6b3b6c4;
}
.slide-arrow.next-arrow {
  position: absolute;
  top: 46%;
  right: -1%;
  z-index: 1;
  background: #fff;
  cursor: pointer;
  box-shadow: 1px 3px 4px #b6b3b6c4;
}

.slide-arrow.gray_arrow {
  background: #ebebeb;
}

.slide-arrow.prev-arrow.gray_arrow {
  top: 31%;
  left: -4%;
}
.slide-arrow.next-arrow.gray_arrow {
  top: 31%;
  right: -4%;
}

/* .header-css .container {
  max-width: 1200px;
} */

.slide-arrow.next-arrow.review-arrow {
  top: auto;
  bottom: -22%;
  left: 8%;
}

.slide-arrow.prev-arrow.review-arrow {
  top: auto;
  bottom: -22%;
  left: 2%;
}

/* =====header-css===== */

.theme_header {
  background-color: #fff;
}

.theme_header .navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

.theme_header .navbar-expand-lg .navbar-nav {
  flex-direction: row;
  align-items: center;
}

.theme_header .navbar.navbar-expand-lg .navbar-nav .nav-item {
  margin: 0 9px;
}

.theme_header .navbar.navbar-expand-lg .navbar-nav .nav-item a {
  color: #141414;
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
  text-decoration: none;
}

.theme_header .navbar.navbar-expand-lg .navbar-nav .nav-item a:hover {
  color: #1d8ff2;
}

.theme_header .navbar-brand img {
  width: 82%;
}

.nav_toggler {
  display: none;
  background: transparent;
  border: 0;
}

.nav_toggler:focus,
.nav_toggler:hover {
  background: #fff !important;
  border: 0;
  box-shadow: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 2.5em;
  height: 1.6em;
}

.offcanvas-header {
  justify-content: space-between;
}

.offcanvas-header .btn-close {
  position: relative;
  top: -18px;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

.offcanvas-header .btn-close:focus,
.offcanvas-header .btn-close:hover {
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

.offcanvas-header {
  padding-bottom: 0;
}

.offcanvas-body {
  padding: 0 10px;
}

.mobile_menu .container .navbar-nav li {
  padding: 10px 0;
  margin-bottom: 6px;
}

.mobile_menu .container .navbar-nav li.list {
  border-bottom: 1px solid #dcdbdb;
}

.mobile_menu .container .navbar-nav .mobile-button li {
  margin-right: 12px;
}

.home-newvideo-box-play .play {
  position: relative;
}

.home-newvideo-box-play .play::before {
  content: '';
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  animation: pulse-border 2.5s linear infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.5);

    opacity: 1;
  }

  50% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);

    opacity: 0;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);

    opacity: 0;
  }
}

.latest_blog
  .blog_inner
  .tab-content
  .tab-pane
  .blog_content
  .blog_inner_wrap:hover {
  box-shadow: 3px 7px 10px #7a7a7a;
}
.navigation:hover {
  color: grey !important;
}

.latest_blog .blog_inner .nav .nav-link .blog_head .blog_img {
  transition: all 0.5s ease;
  border-radius: 16px;
}

.latest_blog .blog_inner .nav .nav-link .blog_head .blog_img:hover {
  box-shadow: 3px 4px 6px #7a7a7a;
}

/* ====header-responsive=== */

@media (max-width: 1299px) {
  /* .header-css .container {
    max-width: 1199px;
  } */
}

@media (max-width: 1199px) {
  /* .header-css .container {
    max-width: 1099px;
  } */

  .full-body-tab .card_slider .slides_card .partner-slide {
    height: 470px;
  }

  .top_companies .companies_card {
    height: 202px;
  }
}

@media (max-width: 1099px) {
  /* .header-css .container {
    max-width: 991px;
  } */

  .slide .slide__text {
    width: 84%;
  }

  .banner .banner_input {
    width: 88%;
    left: 6%;
  }

  .banner .banner_input {
    position: absolute;
    bottom: 10%;
    width: 90%;
    margin: auto;
    left: 5%;
    background: #fff;
    padding: 10px 7px;
    border-radius: 8px;
  }
}

@media (max-width: 991px) {
  .nav_toggler {
    display: block;
    position: relative;
    top: 9px;
  }

  /* .header-css .container {
    max-width: 700px;
  } */

  .banner .slide .slide__text h2 {
    font-size: 50px;
    line-height: 53px;
    margin-bottom: 9px;
  }

  .section-heading .section_head br,
  .section_head br {
    display: none;
  }

  .section-heading .section_head,
  .section_head {
    font-size: 37px;
    line-height: 45px;
    max-width: 100%;
  }

  .full-body-tab .employees_tab .tab-content .employee_tab .right_wrap {
    display: flex;
    justify-content: center;
  }

  .full-body-tab .success_story_section .story_slider .slide-img img {
    width: 100%;
    margin-bottom: 20px;
  }

  .top_companies .border_div {
    display: none;
  }

  .full-body-tab
    .success_story_section.success_story_slider
    .story_slider
    .slide
    .row {
    height: auto;
  }

  .top_companies .companies_card h6 {
    font-size: 23px;
    line-height: 25px;
  }
}

@media (max-width: 767px) {
  /* .header-css .container {
    max-width: 550px;
  } */
  .top_companies {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  /* .header-css .container {
    max-width: 550px;
    padding: 0 15px;
  } */

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 300px;
  }
}

@media (max-width: 380px) {
  /* .header-css .container {
    max-width: 550px;
    padding: 0 15px;
  } */
  .theme_header .navbar-brand {
    width: 70%;
    margin: 0;
  }

  .theme_header .navbar-brand img {
    width: 75%;
  }

  .navbar-toggler-icon {
    width: 2.2em;
    height: 1.3em;
  }

  .skills_intelligence .Intelligence_card {
    height: 240px;
  }
}

/* ==========common-css==== */

.video_section .case_studies_slider .home-newvideo-box-play {
  transition: 1s ease-in-out;
  /* min-height: 549px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.case_studies_slider .home-newvideo-box-content .video-responsive iframe {
  height: 265px;
}

.case_studies_slider .home-newvideo-box:before {
  display: none;
}

.video_section .case_studies_slider .home-newvideo-box .video_thumbnailss {
  position: absolute;
  width: 100%;
  height: 265px;
  left: 0;
  top: 0;
}

.video_section .case_studies_slider .home-newvideo-box .video_thumbnailss img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case_studies.video_section .case_studies_slider .partner-slide .slide__text {
  margin: 10px 15px;
  border-radius: 21px;
  background: #fff;
  box-shadow: 2px 3px 10px #0000003d;
  margin-bottom: 30px;
  transition: all 0.5s ease;
  transform: translateY(1);
  margin-top: 20px;
  height: 263px;
  overflow: hidden;
}

.case_studies.video_section
  .case_studies_slider
  .partner-slide
  .slide__text
  .blog_video {
  height: 100%;
}

.case_studies.video_section .home-newvideo-box {
  height: 100%;
}

