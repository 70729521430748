.couponSection{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.couponSection label{
  margin-left: 6rem;
  margin-bottom: 1rem;
}
.planSection{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.4rem;
}
@media (max-width: 990px) {
  .couponSection label{
    margin-left: 1rem;
  }
}
@media (max-width: 580px) {
  .couponSection{
    flex-direction: column;
    gap: 10px;
  }
  .planSection{
    flex-direction: column;
    gap: 1rem;
  }
}
