.container{
  height: 100%;
  margin-inline: 8rem;
}
.topBar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.topBar h1{
  width: max-content;
}
.topBar input{
  height: max-content;
  border: 2px solid rgb(29,143,242);
  max-width: 30rem;
}
@media (max-width: 1250px) {
  .container{
    margin-inline: 6rem;
  }
}
@media (max-width: 1050px) {
  .container{
    margin-inline: 3rem;
  }
}
@media (max-width:590px) {
  .topBar{
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width:470px) {
  .container{
    margin-inline: 0.5rem;
  }
}