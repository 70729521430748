.container{
  width: 100%;
  padding-inline: 5rem;
  padding-top: 3rem;
}
h3{
  margin-bottom: 10px;
  color: rgb(53, 51, 51);
}
.eventNames{
  color: rgb(56, 56, 247);
}
.eventNames:hover{
  cursor: pointer;
}
.btns{
  display: flex;
  gap: 1rem;
}
.dateMobileView{
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 17rem;
  gap: 10px;
  justify-content: space-between;
  margin-top: 1rem;
}
.dropDown{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  background-color: white;
  color: rgb(97, 89, 89);
  padding: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.19);
  margin-bottom: 1px;
  padding-block: 2rem;
}
.dropDown h4{
  margin: 0;
  padding: 0;
  font-weight: 100;
}
.dropDown:hover{
  cursor: pointer;
}
.pricing_modal{
  width: 50rem;
  height: 40rem;
}
.winnerCard{
  position: relative;
  margin-block: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.4rem;
  margin-right: 1rem;
  margin-bottom: 8rem;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)
  
}
.winnerProfile img{
  width: 100%;
  padding: 0;
}
.winnerBadge img{
  width: 15%;
  position: absolute;
  top: 5%;
  left: 79%;
}
.mobileViewBadge{
  width: 2rem;
  position: absolute;
  top: 5%;
  left: 76%;
}
.winnerName{
  font-size: 1rem;
  margin-top: 10px;
  color: black;
  text-decoration: underline;
  padding: 0;
}

.mobileViewWinner:hover{
  cursor: pointer;
}
.winnerContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}
.mobileViewWinner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  gap: 10px;
  width: 12rem;
  padding: 10px;
  color: black;
  text-decoration: underline;
  position: relative;
  border: 2px solid rgb(25,118,210);
  border-radius: 2px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)

}
.mobileViewWinner img{
  width:100%;
}
.mobileViewWinner p{
  margin: 0;
  padding: 0;
}
.noEvents{
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-content {
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
}

.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
/* 
.form-group input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
} */

.error-message {
  margin-top: 4px;
  font-size: 12px;
  color: #d32f2f;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color:#0b62c4;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #01438a;
}

.tech-name {
  padding: 5px;
}

@media (max-width:690px) {
  .container{
    padding-inline: 1.5rem;
  }
}
@media (max-width:400px) {
  .dropDown{
    width: 300px;
  }
}
